<template>
  <div>
    <!-- Add New Sidebar -->
    <channel-fee-list-sidebar
      :is-channel-fee-list-sidebar-active.sync="isChannelFeeListSidebarActive"
      :data="feeInfo"
      @updated="queryChannelFees"
    />

    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <b-button
          variant="outline-primary"
          :to="{ name: 'channel-list' }"
        >
          <feather-icon
            icon="ArrowLeftIcon"
            class="mr-50"
          />
          <span class="align-middle">Back</span>
        </b-button>

        <b-button
          class="ml-2"
          variant="outline-primary"
          @click="addNewChannelFee"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </b-button>
      </div>

      <b-table
        class="position-relative"
        responsive
        :fields="tableFields"
        :items="fees"
        show-empty
        striped
        :borderless="false"
        empty-text="No matching records found"
      >

        <!-- Column: Name -->
        <template #cell(name)="data">
          <b-media vertical-align="center">
            <span class="font-weight-bold d-block">
              {{ data.item.fee_name }}
            </span>
          </b-media>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.status) }}
          </b-badge>
        </template>

        <!-- Column: PayParty -->
        <template #cell(pay_party)="data">
          {{ resolvePayParty(data.item.pay_party) }}
        </template>

        <!-- Column: FeeType -->
        <template #cell(fee_type)="data">
          {{ resolveFeeType(data.item.fee_type) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              icon="EditIcon"
              class="cursor-pointer"
              size="16"
              @click="showDetail(data.item)"
            />

            <feather-icon
              icon="Trash2Icon"
              size="16"
              class="cursor-pointer mx-1"
              @click="deleteConfirm(data.item)"
            />
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BTable, BButton, BMedia, BBadge,
} from 'bootstrap-vue'
import useChannelsJs from './channels'
import ChannelFeeListSidebar from './ChannelFeeListSidebar.vue'

const {
  fetchChannelFees,
  deleteChannelFee,
} = useChannelsJs()

export default {
  components: {
    ChannelFeeListSidebar,

    BCard,
    BTable,
    BButton,
    BMedia,
    BBadge,
  },
  data() {
    return {
      channelId: this.$route.params.cid,
      fees: [],
      tableFields: [
        'name',
        'pay_party',
        'fee_type',
        'status',
        'actions',
      ],

      isChannelFeeListSidebarActive: false,
      feeInfo: {},
    }
  },
  created() {
    this.queryChannelFees()
  },
  methods: {
    showAlert(icon, title, text) {
      this.$swal({
        icon,
        title,
        text,
        customClass: {
          confirmButton: `btn btn-${icon}`,
        },
      })
    },
    queryChannelFees() {
      fetchChannelFees(
        this.channelId,
        feeList => {
          this.fees = feeList
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
    resolveFeeType(feeType) {
      if (feeType === '300') return 'Platform Default Fee'
      if (feeType === '301') return 'Platform Processing Fee'
      if (feeType === '302') return 'Platform Refund Fee'
      if (feeType === '303') return 'Platform Chargeback Fee'
      if (feeType === '304') return 'Platform AVS Fee'
      if (feeType === '305') return 'Platform Tax Fee'
      if (feeType === '306') return 'Platform Installment Fee'

      if (feeType === '400') return 'Channel Default Fee'
      if (feeType === '401') return 'Channel Processing Fee'
      if (feeType === '402') return 'Channel Refund Fee'
      if (feeType === '403') return 'Channel Chargeback Fee'
      if (feeType === '404') return 'Channel AVS Fee'
      if (feeType === '405') return 'Channel Tax Fee'
      if (feeType === '406') return 'Channel Installment Fee'

      return 'Unknown'
    },
    resolvePayParty(val) {
      const payParty = parseInt(val / 100, 0)
      if (payParty === 3) return 'Platform'
      if (payParty === 4) return 'Bank'
      return 'Unknown'
    },
    resolveStatusVariant(status) {
      if (status === 0) return 'warning'
      if (status === 1) return 'success'
      return 'secondary'
    },
    resolveStatusText(status) {
      if (status === 0) return 'Disabled'
      if (status === 1) return 'Enabled'
      return 'Unknown'
    },
    addNewChannelFee() {
      this.feeInfo = {
        is_new: true,
        channel_id: this.channelId,
      }
      this.isChannelFeeListSidebarActive = true
    },
    showDetail(channelDetail) {
      this.feeInfo = channelDetail
      this.feeInfo.is_new = false
      this.isChannelFeeListSidebarActive = true
    },
    deleteConfirm(feeDetail) {
      this.$swal({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteFee(feeDetail)
        }
      })
    },
    deleteFee(feeDetail) {
      deleteChannelFee(
        feeDetail,
        () => {
          this.showAlert('success', 'Success!', 'Deleted.')
          this.queryChannelFees()
        },
        fail => {
          this.showAlert('warning', 'Fail!', `${fail}`)
        },
      )
    },
  },
}
</script>
